<template>
    <div>
        <img :style="{width:width+'px'}" :src="imgs[pos]"/>
    </div>
</template>

<script>
import {ref,toRef} from 'vue'
export default {
    name:'FlashPlayer',
    props: {
        images: {
            type: Array,
            default: () => []
        },
        width:{
            type:String,
            default:'168'
        },
        height:{
            type:String,
            default:null
        },
    },
    setup(props){
        let imgs=ref(null);
        imgs.value=toRef(props.images);//规定不允许失去对props的响应式
        imgs.value=imgs.value._object;
        //如果图片在使用之前就已经请求过了，那么再次使用的时候，就不会再去请求
        for (let img of imgs.value) {
            let image = new Image();
            // image.onload = () => {
            //     console.log("加载成功")
            // };
            image.src = img;
        }
        let pos=ref(0);
        let next=1;
        
        const nextImage=()=>{
            if(pos.value<=0){
                next=1;
            }else if(pos.value>=imgs.value.length-1){
                next=-1;
            }
            pos.value+=next;
            //image.value=imgs.value[pos];
            //return imgs.value[pos.value];
        }
        
        // let dur=0;
        // let durConst=0;
        // let resume=0;
        // const play=(duration,re)=>{
        //     resume=re;
        //     dur=duration;
        //     durConst=duration;
        //     let recTime=0;
        //     clearInterval(timer);
        //     timer=setInterval(()=>{  
        //         recTime++;
        //         if(recTime>=dur){
        //             nextImage();
        //             recTime=0;
        //         }
        //         if(dur<durConst){
        //             dur+=resume;
        //         }
        //     },1)
        // }

        // const quicken=(speed)=>{
        //     if(dur-speed>7){
        //         dur-=speed;
        //     }
        // }

        
        let timer=null;
        let dur=0;
        
        const setSpeed=(speed)=>{
            //计算每桢间隔多少毫秒
            dur=1000/speed;
        }
        
        const play=(speed)=>{//speed:桢每秒
            setSpeed(speed);
            let recTime=0;
            clearInterval(timer);
            timer=setInterval(()=>{  
                recTime+=5;
                if(recTime>=dur){
                    nextImage();
                    recTime=0;
                }
            },5)
        }

        

        const stop = () => {
            if (timer) clearInterval(timer)
        }
        return {play,stop,nextImage,imgs,setSpeed,pos};
    }
}
</script>

<style scoped lang='less'>
img{
    -webkit-user-drag: none;
}
</style>