export const tones={
    A2A_2B2:[
        require("./A2.mp3"),
        require("./A_2.mp3"),
        require("./B2.mp3")
    ],

    //大字一组
    b1:[
        require("./C1.mp3"),
        require("./C_1.mp3"),
        require("./D1.mp3"),
        require("./D_1.mp3"),
        require("./E1.mp3"),
        require("./F1.mp3"),
        require("./F_1.mp3"),
        require("./G1.mp3"),
        require("./G_1.mp3"),
        require("./A1.mp3"),
        require("./A_1.mp3"),
        require("./B1.mp3")
    ],

    //大字组
    b:[
        require("./C.mp3"),
        require("./C_.mp3"),
        require("./D.mp3"),
        require("./D_.mp3"),
        require("./E.mp3"),
        require("./F.mp3"),
        require("./F_.mp3"),
        require("./G.mp3"),
        require("./G_.mp3"),
        require("./A.mp3"),
        require("./A_.mp3"),
        require("./B.mp3")
    ],

    //小字组
    s:[
        require("./c-.mp3"),
        require("./c-_.mp3"),
        require("./d-.mp3"),
        require("./d-_.mp3"),
        require("./e-.mp3"),
        require("./f-.mp3"),
        require("./f-_.mp3"),
        require("./g-.mp3"),
        require("./g-_.mp3"),
        require("./a-.mp3"),
        require("./a-_.mp3"),
        require("./b-.mp3")
    ],

    //小字一组
    s1:[
        require("./c-1.mp3"),
        require("./c-_1.mp3"),
        require("./d-1.mp3"),
        require("./d-_1.mp3"),
        require("./e-1.mp3"),
        require("./f-1.mp3"),
        require("./f-_1.mp3"),
        require("./g-1.mp3"),
        require("./g-_1.mp3"),
        require("./a-1.mp3"),
        require("./a-_1.mp3"),
        require("./b-1.mp3")
    ],

    //小字二组
    s2:[
        require("./c-2.mp3"),
        require("./c-_2.mp3"),
        require("./d-2.mp3"),
        require("./d-_2.mp3"),
        require("./e-2.mp3"),
        require("./f-2.mp3"),
        require("./f-_2.mp3"),
        require("./g-2.mp3"),
        require("./g-_2.mp3"),
        require("./a-2.mp3"),
        require("./a-_2.mp3"),
        require("./b-2.mp3"),
    ],

    //小字三组
    s3:[
        require("./c3.mp3"),
        require("./c_3.mp3"),
        require("./d3.mp3"),
        require("./d_3.mp3"),
        require("./e3.mp3"),
        require("./f3.mp3"),
        require("./f_3.mp3"),
        require("./g3.mp3"),
        require("./g_3.mp3"),
        require("./a3.mp3"),
        require("./a_3.mp3"),
        require("./b3.mp3"),
    ],

    s4:[
        require("./c4.mp3"),
        require("./c_4.mp3"),
        require("./d4.mp3"),
        require("./d_4.mp3"),
        require("./e4.mp3"),
        require("./f4.mp3"),
        require("./f_4.mp3"),
        require("./g4.mp3"),
        require("./g_4.mp3"),
        require("./a4.mp3"),
        require("./a_4.mp3"),
        require("./b4.mp3"),
    ],

    c5:require("./c5.mp3")

}