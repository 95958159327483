<template>
<div class="wrapper" ref="wrapper">
    <div class="content">
        <template v-if="images&&images.length" >
        <div class="img" v-for="i in images.length" :key="i" >
            <!-- <img :src='images[i-1]' :ref="setImgRef"/> -->
            <img :src='images[i-1].src' :style="{width:images[i-1].width+'px'}"/>
            <div class="dele" @click="remove(i-1)">×</div>
        </div>
        </template>
        <div v-if="tick" class="addScore">
            <input accept=".jpg,.png,.gif,.webp" type="file" hidden ref="inp" @change="addScore"/>
            <div class="but" @click="inp.click()">
                <h3>♫ ♩ ♪ ♬</h3>
                <h1>+</h1>
                <h2>添加乐谱</h2>
            </div>
        </div>
        <div class="adjSize">
            <div class="add" @click="change(0.1)">+</div>
            <div class="value">{{mul}}x</div>
            <div class="dec" @click="change(-0.1)">-</div>
        </div>
    </div>
    <div class="end"></div>
</div>

</template>

<script>
import {nextTick, ref, watch} from 'vue'
//import {useStore} from 'vuex'
//import {getScoreById,queryScoreById} from '../../../api/score'
import {useRoute} from 'vue-router'
import {scores} from "../../../assets/images/score/index";
import axios from "../../../utils/request";
import Message from '../../../components/library/Message';
export default {
    name:'scoreShow',
    setup(){

        let wrapper=ref(null);
        let inp=ref(null);
        let tick=ref(true);
        //const store=useStore();
        let images=ref([]);
        //let t_image=store.state.score.images;

        //根据图片src存储图片信息
        const saveImg=async(src)=>{
            let tObj=new Object({oriWidth:0,width:0,src:null});
            //获取图片原始宽度
            let image = new Image();
            image.src = src;
            image.onload = () => {
                let w=image.width;
                if(w>800)
                    w=800;
                tObj.oriWidth=w;
                tObj.width=w;
                tObj.src=src;
                images.value.push(tObj);
                if(route.path=='/piano/-1')
                    localStorage.setItem("images",JSON.stringify(images.value));
                    //store.commit('score/setImages',images.value);
            };
            
        }

        //图片恢复到原始宽度
        const imgsRes=()=>{
            for(var i=0;i<images.value.length;i++){
                images.value[i].width=images.value[i].oriWidth;
            }
        }

        const route=useRoute();
        if(route.params.id==-1){
            //images.value=store.state.score.images;
            let tImages=JSON.parse(localStorage.getItem("images"));
            if(tImages!=null){
                images.value=tImages;
            }
            imgsRes();
        }else if(route.params.id<-1){
            //let score=getScoreById(route.params.id);
            let score=scores.find(item=>{
                return item.id=route.params.id;
            })
            // let score=route.params.score;
            // console.log("score:");
            // console.log(score);
            for(var i=0;i<score.scoreImageList.length;i++){
                saveImg(score.scoreImageList[i].file.url);
            }
            // for(var i=0;i<score.scoreImages.length;i++){
            //     saveImg(score.scoreImages[i].url);
            // }
        }else{
            // queryScoreById(route.params.id).then((res)=>{
            //     let score=res.data;
            //     for(var i=0;i<score.scoreImages.length;i++){
            //         saveImg(score.scoreImages[i].url);
            //     }
            // });  
            axios.get("/score/getScoreById",{params:{id:route.params.id}}).then((res)=>{
                let score=res;
                for(let i=0;i<score.scoreImageList.length;i++){
                    saveImg(score.scoreImageList[i].file.url);
                }
            }).catch((err)=>{
                Message({type:"error",text:err.message});
            })
        }

        //const addShow=ref(false);

        watch(()=>route.path,(newVal)=>{
            if(newVal=='/piano/-1'){
                //images.value=store.state.score.images;
                let tImages=JSON.parse(localStorage.getItem("images"));
                if(tImages!=null){
                    images.value=tImages;
                }
                imgsRes;
                //addShow.value=true;
            }
            //else{
            //     addShow.value=false;
            // }
        },{immediate:true})

        const addScore=(e)=>{
            let file=e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let image=null;
                image=reader.result;
                saveImg(image);
                //console.log(images.value);
                //store.commit('score/setImages',images.value);
                //store.state.score.images=images.value;//这样直接赋值无法持久化
            };
            tick.value=false;
            nextTick(()=>{
                tick.value=true;
            })
        }
        const remove=(index)=>{
            images.value.splice(index,1);
            if(route.params.id==-1)
                localStorage.setItem("images",JSON.stringify(images.value));
                //store.commit('score/setImages',images.value);
        }

        // let imgs=ref([]);
        // let imgWidths=[];
        // const setImgRef=(el)=>{
        //     console.log('e',el.width);
        //     let re=null;
        //     if(el==null){
        //         return;
        //     }
        //     re=imgs.value.find(function(item){
        //         return item==el;
        //     })
        //     if(!re){
        //         imgs.value.push(el);
        //         imgWidths.push(el.width);
        //     }
        // }
        
        const mul=ref(1.0);
        const changeImgs=()=>{
            for(let i=0;i<images.value.length;i++){
                images.value[i].width=images.value[i].oriWidth*mul.value;
            }
        }
        const change=(num)=>{
            let sum=parseFloat(mul.value)+parseFloat(num);
            if(sum>2||sum<0.1){
                return;
            }
            mul.value=sum.toFixed(1);
            changeImgs();
        }

        return {wrapper,addScore,images,inp,remove,tick,mul,change};
    }
    
}
</script>

<style scoped lang="less">
.wrapper{
    padding-top:30px;
    height: 580px;
    overflow: auto;
    .content{
        width: 800px;
        .img{
            float: left;
            position: relative;
            margin: 3px;
            box-shadow: 0 3px 8px rgba(0,0,0,0.2);
            .dele{
                width: 40px;
                height: 40px;
                background-color: red;
                opacity: 0;
                position:absolute;
                right: 0;
                top:0;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 30px;
            }
            .dele:hover{
                cursor: pointer;
            }
        }
        .img:hover{
            .dele{
                opacity: 1;
            }
        }
    }
    .end{
        height: 350px;
        width: 800px;
        float: left;
    }
    
}

.but{
    float: left;
    width: 150px;
    height: 200px;
    background-color: rgb(213, 213, 217);
    margin: 5px;
    h1{
        line-height: 120px;
        font-size: 200px;
        color: white;
        text-align: center;
        font-weight:50;
    }
    h2{
        line-height: 20px;
        color: white;
        text-align: center;
    }
    h3{
        line-height: 20px;
        color: white;
        text-align: center;
    }
}

.but:hover{
    cursor:pointer;
}

.adjSize{
    width: 40px;
    height: 140px;
    position: absolute;
    top:40px;
    right: 50px;
    .add{
        background-color: white;
        width: 40px;
        height: 40px;
        //border: solid;
        border-radius:20px 20px 10px 10px;
        box-shadow: 0 3px 8px rgba(0,0,0,0.2);
        margin: 5px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        &:hover{
            cursor:pointer;
        }
    }
    .value{
        background-color: white;
        width: 40px;
        height: 50px;
        //border: solid;
        border-radius:10px 10px 10px 10px;
        box-shadow: 0 3px 8px rgba(0,0,0,0.2);
        margin: 5px;
        text-align: center;
        line-height: 50px;
        font-size: 15px;
    }
    .dec{
        background-color: white;
        width: 40px;
        height: 40px;
        //border: solid;
        border-radius:10px 10px 20px 20px;
        box-shadow: 0 3px 8px rgba(0,0,0,0.2);
        margin: 5px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        &:hover{
            cursor:pointer;
        }
    }

}

</style>