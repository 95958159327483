<template>
    <div>
        <div class="white" :style="{width:width+'px'}">
            <div class="wkey" @mousedown="play" @mouseout="hide" @mouseup="hide" :style="{opacity:obj.show.value==true?1:0,background:obj.color.value,width:wKeyWidth+'px'}">
                <audio ref="c5" controls :src="tone" :style="{visibility:'hidden'}"></audio>
            </div>
        </div>
        <img :style="{width:width+'px',height:height+'px'}" src="../../../assets/images/endPiece.png"/>
    </div>
</template>

<script>
import {ref} from 'vue'
import {getElementPagePosition,charUpEffect} from '../../../hooks/index'
export default {
    name:'endPiece',
    props: {
        tone: {
            type: []
        },
        width:{
            type:String,
            default:'24'
        },
        height:{
            type:String,
            default:'250'
        }
    },
    setup(props){
        const c5=ref(null);
        const play=()=>{
            c5.value.currentTime=0;
            c5.value.play();
            obj.show.value=true;
            let ps=getElementPagePosition(c5.value);
            charUpEffect({},ps,c5.value.parentElement.parentElement.parentElement.parentElement);
        }

        const hide=()=>{
            obj.show.value=false;
        };
        var obj=new Object();
        obj.show=ref(false);
        obj.color=ref('rgba(0,0,255,0.8)');
        obj.left=ref(0);
        let wKeyWidth=ref(0);
        wKeyWidth.value=props.width-6;

        return {play,c5,obj,hide,wKeyWidth}
    }
}
</script>

<style scoped lang='less'>
img{
    border: solid 2px;
}

.white{
    bottom: 1px;
    margin-bottom: 5px;
    height: 80px;
    //border: solid red;
    position:absolute;
    display: flex;
}

.wkey{
    border-radius: 5px;
    margin: 3px;
    height:70px;
    &:hover{
        cursor: pointer;
    }
}
img{
    -webkit-user-drag: none;
}
</style>