<template>
<div class="content">
  <HeaderSticky/>
  <div class="banner">
    <div class="left">
      <FlashPlayer ref="player" width="400" :images="images"/>
    </div>
    <div class="right">
      <ScoreShow></ScoreShow>
    </div>
  </div>
  <div class="piano">
    <div class="set">
      默认区域：
      <span class="left">
        left:<select v-model="defLeftPos">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
        </select>
        around:<select v-model="defLeftAround">
          <option>front</option>
          <option>rear</option>
        </select>
      </span>
      &nbsp;
      <span class="right">
        right:<select v-model="defRightPos">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
        </select>
        around:<select v-model="defRightAround">
          <option>front</option>
          <option>rear</option>
        </select>
      </span>
      （回到默认区域：空格）&nbsp;&nbsp;&nbsp;&nbsp;
      <span class="leftTip">
        V:<span>←</span>&nbsp;
        B:<span>→</span>&nbsp;
        G:<span>front/rear</span>&nbsp;
      </span>
      &nbsp;
      <span class="rightTip">
        N:<span>←</span>&nbsp;
        M:<span>→</span>&nbsp;
        H:<span>front/rear</span>&nbsp;
      </span>
    </div>
    <div class="top">
      <span class="htip">[]\</span>
      <span class="etip">"</span>

      <div :style="{left:leftm+'px',width:'168px'}" class="left">{{leftPos}}-left
        <div class="ltip"><span>ZQXWC&nbsp;AESRDTF</span></div>
      </div>
      <div :style="{left:leftEm+'px',width:leftEWidth+'px'}" class="left" >{{leftE}}
        <div class="letip" :style="{width:leftEWidth+'px'}">
          <span v-show="leftE=='front'?true:false">~@!#%$</span>
          <span v-show="leftE=='rear'?true:false">!~@%#&nbsp;$</span>
        </div>
      </div>

      <div :style="{left:rightm+'px',width:'168px'}" class="right">{{rightPos}}-right
        <div class="rtip"><span>JYKUL&nbsp;;I&lt;O&gt;P/</span></div>
      </div>
      <div :style="{left:rightEm+'px',width:rightEWidth+'px'}" class="right">{{rightE}}
        <div class="retip" :style="{width:rightEWidth+'px'}">
          <span v-show="rightE=='front'?true:false">^&)*-(</span>
          <span v-show="rightE=='rear'?true:false">&^*-(&nbsp;)</span>
        </div>
      </div>

    </div>
    <div class="key">
      <HeadPiece height="210" ref="hp" :tones="A2A2_B2Tones"/>
      <Piece height="210" ref="b1" :tones="b1Tones"/>
      <Piece height="210" ref="b" :tones="bTones"/>
      <Piece height="210" ref="s" :tones="sTones"/>
      <Piece height="210" ref="s1" :tones="s1Tones"/>
      <Piece height="210" ref="s2" :tones="s2Tones"/>
      <Piece height="210" ref="s3" :tones="s3Tones"/>
      <Piece height="210" ref="s4" :tones="s4Tones"/>
      <EndPiece height="210" ref="c5" :tone="c5Tone"/>
    </div>
  </div>
</div>
</template>

<script>
import { computed, ref,watch,onUnmounted } from 'vue'
import {tones} from '../../assets/keyTone/index'
import {images} from '../../assets/images/dance/index'
import HeadPiece from './components/headPiece.vue'
import Piece from './components/piece.vue'
import EndPiece from './components/endPiece.vue'
import FlashPlayer from '../../components/other/flashPlayer.vue'
import ScoreShow from './components/scoreShow.vue'
import HeaderSticky from './components/headerSticky.vue'
import {useStore} from 'vuex'
export default {
  name: 'piano',
  components:{
      HeadPiece,
      Piece,
      EndPiece,
      FlashPlayer,
      ScoreShow,
      HeaderSticky
  },
  setup () {
    
    let player=ref(null);
    const allTones={A2A2_B2Tones:[],b1Tones:[],bTones:[],sTones:[],s1Tones:[],s2Tones:[],s3Tones:[],s4Tones:[],c5Tone:null};
    //大字二组(A2,A2_,B2)
    allTones.A2A2_B2Tones=tones.A2A_2B2;

    //大字一组
    allTones.b1Tones=tones.b1;

    //大字组
    allTones.bTones=tones.b;

    //小字组
    allTones.sTones=tones.s;

    //中央C

    //小字一组
    allTones.s1Tones=tones.s1;

    //小字二组
    allTones.s2Tones=tones.s2;

    //小字三组
    allTones.s3Tones=tones.s3;

    //小字四组
    allTones.s4Tones=tones.s4;

    //c5
    allTones.c5Tone=tones.c5;

    const psObj={hp:ref(null),b1:ref(null),b:ref(null),s:ref(null),s1:ref(null),s2:ref(null),s3:ref(null),s4:ref(null),c5:ref(null)}
    const ps=[];
    ps[0]=psObj.hp;
    ps[1]=psObj.b1;
    ps[2]=psObj.b;
    ps[3]=psObj.s;
    //中央c
    ps[4]=psObj.s1;
    ps[5]=psObj.s2;
    ps[6]=psObj.s3;
    ps[7]=psObj.s4;
    ps[8]=psObj.c5;
  
    let leftPos=ref(3);
    let rightPos=ref(4);
    let defLeftPos=ref(3);
    let defRightPos=ref(4);
    let defLeftAround=ref("front");
    let defRightAround=ref("rear");
    let leftE=ref("front");
    let rightE=ref("rear");
    let keyTag={};

    const store=useStore();
    defLeftPos.value=store.state.score.pianoSet.leftPos;
    defRightPos.value=store.state.score.pianoSet.rightPos;
    defLeftAround.value=store.state.score.pianoSet.leftE;
    defRightAround.value=store.state.score.pianoSet.rightE;
    leftPos.value=defLeftPos.value;
    rightPos.value=defRightPos.value;
    leftE.value=defLeftAround.value;
    rightE.value=defRightAround.value;

    watch(() => leftPos.value, (newVal) => {
      if(newVal==7){
        leftE.value="font";
      }else if(newVal==1){
        leftE.value="rear";
      }
    }, { immediate: true })

    watch(() => defLeftAround.value, () => {
      if(defLeftPos.value==7){
        defLeftAround.value="front";
      }else if(defLeftPos.value==1){
        defLeftAround.value="rear";
      }
      store.state.score.pianoSet.leftE=defLeftAround.value;
      store.commit("score/setPianoSet",store.state.score.pianoSet);
    }, { immediate: true })

    watch(() => defLeftPos.value, (newVal) => {
      if(newVal==7){
        defLeftAround.value="front";
      }else if(newVal==1){
        defLeftAround.value="rear";
      }
      store.state.score.pianoSet.leftPos=defLeftPos.value;
      store.commit("score/setPianoSet",store.state.score.pianoSet);
    }, { immediate: true })


    watch(() => rightPos.value, (newVal) => {
      if(newVal==7){
        rightE.value="front";
      }else if(newVal==1){
        rightE.value="rear";
      }
    }, { immediate: true })

    watch(() => defRightAround.value, () => {
      if(defRightPos.value==7){
        defRightAround.value="front";
      }else if(defRightPos.value==1){
        defRightAround.value="rear";
      }
      store.state.score.pianoSet.rightE=defRightAround.value;
      store.commit("score/setPianoSet",store.state.score.pianoSet);
    }, { immediate: true })

    watch(() => defRightPos.value, (newVal) => {
      if(newVal==7){
        defRightAround.value="front";
      }else if(newVal==1){
        defRightAround.value="rear";
      }
      store.state.score.pianoSet.rightPos=defRightPos.value;
      store.commit("score/setPianoSet",store.state.score.pianoSet);
    }, { immediate: true })

    const leftm=computed(()=>{
      return (leftPos.value-1)*168+46;
    })

    const rightm=computed(()=>{
      return (rightPos.value-1)*168+46;
    })

    const leftEWidth=computed(()=>{
      if(leftE.value=="front"){
        return 24*3+8;
      }else{
        return 23*4;
      }
    })

    const rightEWidth=computed(()=>{
      if(rightE.value=="front"){
        return 24*3+8;
      }else{
        return 23*4;
      }
    })

    const leftEm=computed(()=>{
      if(leftE.value=="rear"){
        return leftm.value+168;
      }else{
        return leftm.value-leftEWidth.value;
      }
    })

    const rightEm=computed(()=>{
      if(rightE.value=="rear"){
        return rightm.value+168;
      }else{
        return rightm.value-rightEWidth.value;
      }
    })
    let playStatus=false;
    let timer=null;
    let cdTimer=null;
    let stopDur=1000;
    let clickDur=0;
    let speed=10;//桢每秒

    const startStop=()=>{
      if (timer) clearTimeout(timer);
      timer=setTimeout(()=>{
        player.value.stop();
        playStatus=false;
        clickDur=0;
      },stopDur)
    }

    cdTimer=setInterval(()=>{
      if(clickDur>stopDur){
        clickDur=0;
        player.value.setSpeed(speed);
      }
      if(playStatus)
        clickDur+=5;
    },5);

    // 组件销毁，清理定时器
    onUnmounted(() => {
      clearInterval(cdTimer)
    })

    const dance=()=>{
      if(playStatus==false){
        // player.value.play(27,0.05);
        player.value.play(speed);
        playStatus=true;
      }
      startStop(stopDur);
      //player.value.nextImage();
    }
    
   
    const countAndSetSpeed=()=>{
      if(clickDur==0)
        return;
      let sp=(1+1-clickDur/stopDur)*speed;
      player.value.setSpeed(sp);
      clickDur=0;
    }

    // let leftQui=1;
    // let rightQui=3;
    const play=(pos,ele)=>{
      dance();
      let lrPos;
      let color;
      if(ele=="left"){
        //player.value.quicken(leftQui);
        color='rgba(255,0,0,0.8)';
        lrPos=leftPos.value;
      }else if(ele=="right"){
        countAndSetSpeed();
        //player.value.quicken(rightQui);
        color='rgba(0,255,0,0.8)';
        lrPos=rightPos.value;
      }else if(ele=="leftE"){
        //player.value.quicken(leftQui);
        color='rgba(255,0,0,0.8)';
        if(leftE.value=="rear"){
          lrPos=parseInt(leftPos.value)+1;
          switch(pos){
            case 1:
              pos=2;break;
            case 2:
              pos=1;break;
            case 3:
              pos=3;break;
            case 4:
              pos=5;break;
            case 5:
              pos=6;break;
            case 6:
              pos=4;break;
          }
        }else if(leftE.value=="front"){
          lrPos=parseInt(leftPos.value)-1;
          switch(pos){
            case 1:
              pos=7;break;
            case 2:
              pos=9;break;
            case 3:
              pos=8;break;
            case 4:
              pos=10;break;
            case 5:
              pos=12;break;
            case 6:
              pos=11;break;
          }
        }
      }else if(ele=="rightE"){
        //player.value.quicken(rightQui);
        countAndSetSpeed();
        color='rgba(0,255,0,0.8)';
        if(rightE.value=="rear"){
          lrPos=parseInt(rightPos.value)+1;
          switch(pos){
            case 1:
              pos=2;break;
            case 2:
              pos=1;break;
            case 3:
              pos=3;break;
            case 4:
              pos=5;break;
            case 5:
              pos=6;break;
            case 6:
              pos=4;break;
          }
        }else if(rightE.value=="front"){
          lrPos=parseInt(rightPos.value)-1;
          switch(pos){
            case 1:
              pos=7;break;
            case 2:
              pos=8;break;
            case 3:
              pos=10;break;
            case 4:
              pos=12;break;
            case 5:
              pos=9;break;
            case 6:
              pos=11;break;
          }
        }
      }
      ps[lrPos].value.play(pos,color);
    }

    const hideLeftAll=()=>{
      ps[leftPos.value].value.hideAll();
      for(var i=1;i<=6;i++){
        hide(i,"leftE");
      }
    }

    const hideRightAll=()=>{
      ps[rightPos.value].value.hideAll();
      for(var i=1;i<=6;i++)
        hide(i,"rightE");
    }

    document.addEventListener('keydown',function(e){
      if(keyTag[e.keyCode]==true){
        return;
      }
      switch(e.keyCode){
        case 90://z
          play(1,"left");
          break;
        case 81://q
          play(2,"left");
          break;
        case 88://x
          play(3,"left");
          break;
        case 87://w
          play(4,"left");
          break;
        case 67://c
          play(5,"left");
          break;
        case 65://a
          play(6,"left");
          break;
        case 69://e
          play(7,"left");
          break;
        case 83://s
          play(8,"left");
          break;
        case 82://r
          play(9,"left");
          break;
        case 68://d
          play(10,"left");
          break; 
        case 84://t
          play(11,"left");
          break;
        case 70://f
          play(12,"left");
          break;

        //rigth
        case 74://j
          play(1,"right")
          break;
        case 89://y
          play(2,"right")
          break;
        case 75://k
          play(3,"right")
          break;
        case 85://u
          play(4,"right")
          break;
        case 76://l
          play(5,"right")
          break;
        case 186:
        case 59://;
          play(6,"right");
          break;
        case 73://i
          play(7,"right")
          break;
        case 188://,
          play(8,"right")
          break;
        case 79://o
          play(9,"right")
          break;
        case 190://.
          play(10,"right")
          break;
        case 80://p
          play(11,"right")
          break;
        case 191:///
          play(12,"right")
          break;
        case 54://6
          play(1,"rightE")
          break;
        case 55://7
          play(2,"rightE")
          break;
        case 56://8
          play(3,"rightE")
          break;
        case 57://9
          play(4,"rightE")
          break;
        case 48://0
          play(5,"rightE")
          break;
        case 189:
        case 173://-
          play(6,"rightE");
          break;
        case 192://`
          play(1,"leftE")
          break;
        case 49://1
          play(2,"leftE")
          break;
        case 50://2
          play(3,"leftE")
          break;
        case 51://3
          play(4,"leftE")
          break;
        case 52://4
          play(5,"leftE")
          break;
        case 53://5
          play(6,"leftE")
          break;
        case 219://[
          ps[0].value.play(1);
          dance();
          //player.value.quicken(leftQui);
          break;
        case 221:
          ps[0].value.play(2);
          dance();
          //player.value.quicken(leftQui);
          break;
        case 220:
          ps[0].value.play(3);
          dance();
          //player.value.quicken(leftQui);
          break;
        case 222:
          ps[8].value.play();
          dance();
          //player.value.quicken(rightQui);
          break;
        case 66://b
          if(leftPos.value<7){
            hideLeftAll();
            ++leftPos.value;
          }
          break;  
        case 86://v
          if(leftPos.value>1){
            hideLeftAll();
            --leftPos.value;
          }
          break;
        case 77://m
          if(rightPos.value<7){
            hideRightAll();
            ++rightPos.value;
          }
          break;
        case 78://n
          if(rightPos.value>1){
            hideRightAll();
            --rightPos.value;
          }
          break;
        case 71://g
          if(leftPos.value!=1&&leftPos.value!=7){
            if(leftE.value=="front"){
              leftE.value="rear";
            }else{
              leftE.value="front"
            }
          }
          break;
        case 72://h
          if(rightPos.value!=1&&rightPos.value!=7){
            if(rightE.value=="front"){
              rightE.value="rear";
            }else{
              rightE.value="front"
            }
          }
          break;
        case 32://空格
          e.preventDefault();
          rightPos.value=defRightPos.value;
          rightE.value=defRightAround.value;
          leftPos.value=defLeftPos.value;
          leftE.value=defLeftAround.value;
          break;
        // case 18://alt
        //   e.preventDefault();
        //   leftPos.value=defLeftPos.value;
        //   leftE.value=defLeftAround.value;
        //   break;
      }
      keyTag[e.keyCode]=true;
    });

    const hide=(pos,ele)=>{
      let lrPos;
      if(ele=="left"){
        lrPos=leftPos.value;
      }else if(ele=="right"){
        lrPos=rightPos.value;
      }else if(ele=="leftE"){
        if(leftE.value=="rear"){
          lrPos=parseInt(leftPos.value)+1;
          switch(pos){
            case 1:
              pos=2;break;
            case 2:
              pos=1;break;
            case 3:
              pos=3;break;
            case 4:
              pos=5;break;
            case 5:
              pos=6;break;
            case 6:
              pos=4;break;
          }
        }else if(leftE.value=="front"){
          lrPos=parseInt(leftPos.value)-1;
          switch(pos){
            case 1:
              pos=7;break;
            case 2:
              pos=9;break;
            case 3:
              pos=8;break;
            case 4:
              pos=10;break;
            case 5:
              pos=12;break;
            case 6:
              pos=11;break;
          }
        }
      }else if(ele=="rightE"){
        if(rightE.value=="rear"){
          lrPos=parseInt(rightPos.value)+1;
          switch(pos){
            case 1:
              pos=2;break;
            case 2:
              pos=1;break;
            case 3:
              pos=3;break;
            case 4:
              pos=5;break;
            case 5:
              pos=6;break;
            case 6:
              pos=4;break;
          }
        }else if(rightE.value=="front"){
          lrPos=parseInt(rightPos.value)-1;
          switch(pos){
            case 1:
              pos=7;break;
            case 2:
              pos=8;break;
            case 3:
              pos=10;break;
            case 4:
              pos=12;break;
            case 5:
              pos=9;break;
            case 6:
              pos=11;break;
          }
        }
      }
      ps[lrPos].value.hide(pos);

    };

    document.addEventListener('keyup',function(e){
      keyTag[e.keyCode]=false;
      switch(e.keyCode){
        case 90://z
          hide(1,"left");break;
        case 81://q
          hide(2,"left");break;
        case 88://x
          hide(3,"left");break;
        case 87://w
          hide(4,"left");break;
        case 67://c
          hide(5,"left");break;
        case 65://a
          hide(6,"left");break;
        case 69://e
          hide(7,"left");break;
        case 83://s
          hide(8,"left");break;
        case 82://r
          hide(9,"left");break;
        case 68://d
          hide(10,"left");break; 
        case 84://t
          hide(11,"left");break;
        case 70://f
          hide(12,"left");break;

        //rigth
        case 74://j
          hide(1,"right");break;
        case 89://y
          hide(2,"right");break;
        case 75://k
          hide(3,"right");break;
        case 85://u
          hide(4,"right");break;
        case 76://l
          hide(5,"right");break;
        case 186:
        case 59://;
          hide(6,"right");break;
        case 73://i
          hide(7,"right");break;
        case 188://,
          hide(8,"right");break;
        case 79://o
          hide(9,"right");break;
        case 190://.
          hide(10,"right");break;
        case 80://p
          hide(11,"right");break;
        case 191:///
          hide(12,"right");break;
        case 54://6
          hide(1,"rightE");break;
        case 55://7
          hide(2,"rightE");break;
        case 56://8
          hide(3,"rightE");break;
        case 57://9
          hide(4,"rightE");break;
        case 48://0
          hide(5,"rightE");break;
        case 189:
        case 173://-
          hide(6,"rightE");break;
        case 192://`
          hide(1,"leftE");break;
        case 49://1
          hide(2,"leftE");break;
        case 50://2
          hide(3,"leftE");break;
        case 51://3
          hide(4,"leftE");break;
        case 52://4
          hide(5,"leftE");break;
        case 53://5
          hide(6,"leftE");break;
        case 219://[
          ps[0].value.hide(1);break;
        case 221:
          ps[0].value.hide(2);break;
        case 220:
          ps[0].value.hide(3);break;
        case 222:
          ps[8].value.hide();break;
      }
    });

    // let imgs=ref(null);
    // //如果图片在使用之前就已经请求过了，那么再次使用的时候，就不会再去请求
    // for (let img of images) {
    //     let image = new Image();
    //     // image.onload = () => {
    //     //     console.log("加载成功")
    //     // };
    //     image.src = img;
    // }
    
    //imgs.value=images;
    return {...allTones,...psObj,leftPos,rightPos,leftm,rightm,leftE,rightE,leftEm,leftEWidth,rightEm,rightEWidth,
               defLeftPos,defRightPos,defLeftAround,defRightAround,images,player}
  }
}
</script>

<style scoped lang='less'>
  .content{
    width: 100%;
    background-color: white;
  }
  .piano{
    width: 100%;
    //width:1248px;
    position:fixed;
    bottom: 0;
    .set{
      width:1248px;
      margin: auto;
      margin-bottom: 5px;
      // .left{
      //   color: rgba(255,0,0,0.5);
      // }
      // .right{
      //   color: rgba(0,255,0,0.5);
      // }
      .leftTip{
        span{
          color: rgba(255,0,0,0.5);
        }
      }
      .rightTip{
        span{
          color: rgba(0,255,0,0.5);
        }
      }
    }
    .top{
      width: 1248px;
      height: 26px;
      border: solid 1px;
      //box-shadow: 0 3px 8px rgba(0,0,0,0.2);
      margin: auto;
      padding: 1px;
      margin-bottom: 2px;
      position: relative;
      border-radius: 5px;
      background: #ffffff;
      z-index: 99999;
      .left{
        height: 20px;
        background:rgba(255,0,0,0.5);
        position:absolute;
        left:48px;
        border-radius: 5px;
      }
      .right{
        height: 20px;
        background:rgba(0,255,0,0.5);
        position:absolute;
        left: 500px;
        border-radius: 5px;
      }
      div{
        text-align: center;
      }
      .htip{
        float: left;
        letter-spacing: 6px;
        margin-left: 8px;
        color:rgba(0,0,255,1);
      }
      .etip{
        float: right;
        letter-spacing: 6px;
        margin-right: 4px;
        color:rgba(0,0,255,1);
      }
      .ltip{
        width: 168px;
        height: 30px;
        //border: solid;
        margin-top: 5px;
        span{
          color: rgba(255,0,0,1);
          letter-spacing: 3px;
          font-size: 15px;
          font-family: 'Courier New', Courier, monospace;
          margin-left: 5px;
        }
      }
      .rtip{
        width: 168px;
        height: 30px;
        //border: solid;
        margin-top: 5px;
        span{
          color: rgba(0,255,0,1);
          letter-spacing: 3px;
          font-size: 15px;
          font-family: 'Courier New', Courier, monospace;
          margin-left: 5px;
        }
      }
      .letip{
        height: 30px;
        margin-top: 5px;
        span{
          color: rgba(255,0,0,1);
          letter-spacing: 3px;
          font-size: 15px;
          font-family: 'Courier New', Courier, monospace;
          margin-left: 5px;
        }
      }

      .retip{
        height: 30px;
        margin-top: 5px;
        span{
          color: rgba(0,255,0,1);
          letter-spacing: 3px;
          font-size: 15px;
          font-family: 'Courier New', Courier, monospace;
          margin-left: 5px;
        }
      }
    }
    .key{
      margin: auto;
      width: 1248px;
      display: flex;
      box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    }
  }
  .banner{
    width:1248px;
    height: 400px;
    margin: auto;
    //border: solid;
    background-color:#ffffff;
    display: flex;
    .left{
      width: 400px;
      //border: solid;
      height: 400px;
    }
    .right{
      width: 848px;
      //border: solid;
    }
  }
  .layout{
    width: 100%;
    background-color:#ffffff;
  }

  select:hover{
    cursor: pointer;
  }
</style>
