<template>
  <div class="header-sticky" :class="{show:show}" @mouseleave="show=false">
    <div class="container">
      <Header :navs="nav"/>
    </div>
  </div>
</template>

<script>
import Header from '../../components/header.vue';
import { ref, watch} from 'vue';
import {useStore} from 'vuex'
export default {
  name: 'HeaderSticky',
  components: { Header },
  //非组合api实现
  setup () {
    const store=useStore();
    const nav=ref([]);
    nav.value=store.state.navs.nav;

    const show=ref(false);
    let y=ref(100);
    document.onmousemove=(event)=>{
        y.value=event.pageY;
    }
    watch(()=>y.value,(newVal)=>{
      if(newVal<30){
        show.value=true;
      }
    })
    return  {show,nav} 
  }
}
</script>

<style scoped lang='less'>
.header-sticky {
  z-index: 9999;
  width: 100%;
  //height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #e4e4e4;
  
  transform: translateY(-100%);

  opacity: 0;
  transition: all 0.3s linear;
  &.show {
    transition: all 0.3s linear;
    transform: none;
    opacity: 1;
  }

  .container {
    display: flex;
    align-items: center;
  }
}
</style>
