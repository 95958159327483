export const images=[
    require("./image0.png"),
    require("./image1.png"),
    require("./image2.png"),
    require("./image3.png"),
    require("./image4.png"),
    require("./image5.png"),
    require("./image6.png"),
    require("./image7.png"),
    require("./image8.png"),
    require("./image9.png"),
    require("./image10.png"),
    require("./image11.png"),
    require("./image12.png"),
    require("./image13.png"),
    require("./image14.png"),
    require("./image15.png"),
    require("./image16.png"),
    require("./image17.png"),
    require("./image18.png"),
    require("./image19.png"),
    require("./image20.png"),
    require("./image21.png"),
    require("./image22.png"),
    require("./image23.png"),
    require("./image24.png"),
    require("./image25.png"),
    require("./image26.png"),
    require("./image27.png"),
    require("./image28.png"),
    require("./image29.png"),
    require("./image30.png"),
    require("./image31.png"),
    require("./image32.png"),
    require("./image33.png"),
    require("./image34.png"),
    require("./image35.png"),
    require("./image36.png"),
    require("./image37.png"),
    require("./image38.png"),
    require("./image39.png"),
    require("./image40.png"),
    require("./image41.png"),
    require("./image42.png"),
    require("./image43.png"),
    require("./image44.png"),
    require("./image45.png"),
    require("./image46.png"),
    require("./image47.png"),
    require("./image48.png"),
    require("./image49.png"),
    require("./image50.png"),
    require("./image51.png"),
    require("./image52.png"),
    require("./image53.png"),
    require("./image54.png"),
    require("./image55.png"),
    require("./image56.png"),
    require("./image57.png"),
    require("./image58.png"),
    require("./image59.png"),
    require("./image60.png"),
    require("./image61.png"),
    require("./image62.png"),
    require("./image63.png")
]